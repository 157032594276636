.purchase {
  width: 100%;
  max-width: 632px;
  margin: 0 auto;

  &Maintitle {
    margin-bottom: 15px !important;
  }
  &Subtitle {
    margin-bottom: 40px !important;
  }
  &Rules{
    color: #EBFF22;
    cursor: pointer;
  }
  &Total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }
  &Quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    &Number {
      display: flex;
      align-items: center;
      gap: 8px;
      &Tooltip {
        width: 18px;
        height: 18px;
        position: relative;
      }
      &Icon {
        width: 18px;
        height: 18px;
        color: var(--color-gray-400);
        cursor: pointer;

        &:hover {
          color: var(--color-gray-300);
        }
      }
    }
  }
  &Button {
    text-align: center;
  }
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
