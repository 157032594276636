.dropdown {
  z-index: 100;

  &Button {
    background: transparent;
    padding: 0;
    border: none;
    font-family: inherit;
    position: relative;
    cursor: pointer;
  }

  &Verified {
    position: absolute;
    bottom: 0;
    right: -8px;
    z-index: 2;
    width: 18px;
    height: 18px;
  }

  &Budge {
    position: absolute;
    top: -2px;
    right: -4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--color-error);
    border: 2px solid var(--color-background);
  }

  &Transition {
    &Enter {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.9) translateY(-12px);
      transform-origin: top 40px;
    }

    &EnterActive {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      transition:
        opacity 300ms,
        transform 300ms;
    }

    &Exit {
      opacity: 1;
      visibility: visible;
    }

    &ExitActive {
      opacity: 0;
      visibility: hidden;
      transform-origin: top 40px;
      transform: scale(0.9) translateY(-12px);
      transition:
        opacity 300ms,
        transform 300ms;
    }
  }

  &Options {
    position: absolute;
    right: -40px;
    top: calc(100% + 12px);
    box-shadow: 0px 4px 14px 0px #0000004d;
    outline: 1px solid var(--color-gray-800);
    background-color: var(--color-background);
    padding: 8px;
    border-radius: 4px;
    width: 256px;
    z-index: 100;
  }
}

.list {
  &Item {
    border-bottom: 1px solid var(--color-gray-900);
    margin-bottom: 11px;
    padding-bottom: 12px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &Element {
    position: relative;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: var(--color-white);
    text-align: left;
    padding: 0;
    transition: background-color 0.2s;
    padding: 12px 16px;
    border-radius: 4px;
    > p {
      display: flex;
      gap: 8px;
    }
    &Budge {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-error);
    }

    &:hover {
      background-color: var(--color-gray-900);
    }
  }
}
