.slider {
  &Item {
    display: flex;
    justify-content: center;
    padding-top: 88px !important;
    padding-bottom: 190px !important;
  }
  :global {
    .slick-list {
      padding-top: 88px !important;
      padding-bottom: 190px !important;
      overflow: visible !important;
    }
  }
}
