.rules {

  &Li{
    list-style: none;
    margin-bottom: 8px;
    padding-left: 15px;
    font-size: 16px;
    color: #fff;
    position: relative;

    &::before {
      content: "•";
      color: #EBFF22;
      padding-right: 15px;
      font-size: 25px;
      position: absolute;
      left: -2px;
    }
  }
}