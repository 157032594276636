.event {
  transition: transform 0.3s;
  position: relative;
  border-radius: 6px;
  backface-visibility: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: -1;
    background-color: var(--color-black);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    will-change: transform;
    border-radius: 6px;
  }

  .actionRequired {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600px;
    position: absolute;
    background: var(--color-error);
    color: var(--color-white);
    border-radius: 12px 12px 0px 0px;
    transition: all 0.3s;
    height: 22px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    top: -22px;
    right: 20px;
  }

  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
      height: calc(100% + 48px);
      width: calc(100% + 32px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    .eventType {
      transform: translateY(-54px) translateX(12px);
    }

    .actionRequired {
      transform: translateY(-54px) translateX(12px);
    }

    .eventTitle {
      font-size: 28px;
    }

    .eventInfo {
      transform: translateY(-54px);
    }

    .eventCategories {
      opacity: 1;
      visibility: visible;
    }

    .eventBuy {
      opacity: 1;
      visibility: visible;
      bottom: -15px;
    }

    .eventImage {
      transform: translateY(-54px);

      img {
        max-width: calc(100% + 32px);
        height: 252px;
      }

      &::after {
        width: calc(100% + 32px);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &Type {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 90;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s;

    &Item {
      backdrop-filter: blur(30px);
      background: #38394533;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.live {
        width: 67px;
        background-color: #fe250e;
        border-radius: 40px;
        justify-content: space-between;
        padding: 0 8px;
      }
    }
  }

  &Categories {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    position: absolute;
    top: -46px;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  &Category {
    backdrop-filter: blur(30px);
    background: #3839454d;
    border: 1px solid #ffffff29;
    border-radius: 40px;
    padding: 4px 12px;
  }

  &Buy {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    position: absolute;
    bottom: 0px;
  }

  &Watch{

  }

  &Date {
    display: flex;
    align-items: center;
    gap: 12px;

    &Divider {
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-gray-200);
      border-radius: 50%;
    }
  }

  &Title {
    transition: font-size 0.3s;
    will-change: transform;
    color: var(--color-white);

    &:hover {
      text-decoration: underline;
    }
  }

  &Info {
    position: relative;
    z-index: 90;
    transition: all 0.3s;
    will-change: transform;
  }

  &Image {
    display: flex;
    justify-content: center;
    transition: all 0.3s;
    will-change: transform;
    backface-visibility: hidden;
    height: 252px;

    img {
      backface-visibility: hidden;
      transition: all 0.3s;
      aspect-ratio: 16 / 9;
      height: 234px;
      border-radius: 7px;
    }

    &::after {
      backface-visibility: hidden;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      height: 253px;
      width: 100%;
      transform: translateX(-50%);
      z-index: 80;
      background: linear-gradient(180deg, var(--color-black) 0%, rgba(27, 27, 36, 0.510099) 51.01%, var(--color-black) 100%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      border-radius: 6px;
    }
  }
}

.allocationBudge {
  position: absolute;
  top: -7px;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--color-error);
  border: 2px solid var(--color-background);
}